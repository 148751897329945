import React, {useRef, useState, useEffect}  from 'react';
import { useThemeContext } from '../../providers/ThemeProvider/Provider';
import { useArticlesContext } from '../../providers/BlogProvider/Provider';
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins } from '@fortawesome/free-solid-svg-icons';
import { faAndroid } from '@fortawesome/free-brands-svg-icons';
import ReactTypingEffect from 'react-typing-effect';
import {ReactComponent as Pinata} from '../../images/pinata.svg';
import { device } from '../../utils/devices';
import './Home.styles.scss';


const Container = styled.div`
    min-height: 80vh;
    max-width: 75rem;
    padding-top: 50px;
    margin: auto;
    margin-bottom: 50px;
    @media only screen and ${device.tablet}{ 
       
      }
`;

const Column1 = styled.div`
    float: left;
    padding: 20px;

    @media only screen and ${device.tablet}{ 
        width: 40%;
        padding: 10px;
      }
`;

const Column2 = styled.div`
    text-align: center;
    display: none;
    float: right;
    width: 40%;
    padding: 10px;

    @media only screen and ${device.tablet}{ 
        display: unset;
      }
`;

const SquareContainer = styled.div`
    width: 100%;
    height: auto;
    border: 2px solid ${(props) => props.theme.divider};
    padding: 10px 10px 40px 10px;
    box-shadow: 5px 10px ${(props) => props.theme.divider};
    background-color: ${(props) => props.theme.background};
`;

const ImageContainer = styled.div`
    width: auto;
    height: auto;
    
    svg {
        width: 30%;
        height: auto;
        margin: 10px;
    }
`;

const FooterContainer = styled.div`
    min-height: 5vh;
    text-align: center;
    padding-top: 10px;
    font-family: 'DM Sans';
    font-size: 12px;
    div a{
        text-decoration: none;
        color: ${(props) => props.theme.primaryText};
        cursor: pointer;
    }
`;

const Title = styled.span`
    font-size: 62px;
    font-family: 'Passion One';
    letter-spacing: .08rem;
    color: ${(props) => props.theme.primaryText};
`;

const SubTitle = styled.span`
    font-size: 20px;
    font-family: 'Passion One';
    letter-spacing: .08rem;
    color: ${(props) => props.theme.primaryText};
`;

const TextContent = styled.span`
    text-align: justify;
    text-justify: inter-word;
    font-family: 'DM Sans';
    color: ${(props) => props.theme.body};
`;

const WelcomeText = styled.p`
    margin-top: 42px;
    font-size: 20px;
    text-align: justify;
    text-justify: inter-word;
    font-family: 'DM Sans';
    color: ${(props) => props.theme.secondaryText};
    span {
        font-weight: bold;
        color: ${(props) => props.theme.primaryText};
    }
`;

const ButtonContact = styled.button`
    border: 1px solid ${(props) => props.theme.divider};
    padding: 12px;
    box-shadow: 5px 5px ${(props) => props.theme.divider};
    font-weight: bold;
    cursor: pointer;
    background: ${(props) => props.theme.buttons};
    color: ${(props) => props.theme.primaryText};
    text-decoration: none;
    &:active {
        background-color: ${(props) => props.theme.background};
        transform: translateY(4px);
      }
`;


function HomePage(){

  const sectionRef = useRef(null);
  const { theme } = useThemeContext();
  const { setCurrentScreen } = useArticlesContext();
  const [clickCount, setclickCount] = useState(0)
  const [isRotate, setIsRotate] = useState(false)
 
 
  const clickButton = () => {
    setclickCount(clickCount + 1);
    
    if(clickCount === 3 && !isRotate){
        setIsRotate(true)
    }
    setTimeout(function(){ setclickCount(0)}, 3000)
 }

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setCurrentScreen(1);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);


    return(
        <section className="home-page" ref={sectionRef}>
            <Container className="row">
                <Column1 className="column"> 
                    <Title>
                        WELCOME TO MY
                         <br/>
                        <ReactTypingEffect text={["PAGE.", "SITE.", "BLOG.", "WEB.", "SPACE.", "'HELO WORLD'."]} speed="100" typingDelay="100" />
                    </Title>
                    <WelcomeText>
                            I Like to code <span>Android</span> apps <FontAwesomeIcon icon={faAndroid} style={{ color: theme.botIcon }} size="1x" /> and handsome <span>Websites</span>.
                            When I'm not coding I'm at my Job... 
                            <span> Joke!</span> Dont tell my boss <FontAwesomeIcon icon={faCoins} style={{ color: theme.coinIcon }} size="1x" />
                            <br/>     <br/> 
                            <span>Hope my content helps you!</span>
                    </WelcomeText>
                    <ButtonContact onClick={clickButton}> 
                    { !isRotate ? "DO NOT CLICK, PLEASE" : "TOLD YA! 😭 " }
                    </ButtonContact>
                </Column1>
                <Column2 className="column"> 
                    <SquareContainer>
                        <ImageContainer>
                          <Pinata className={isRotate ? "rotateImage" : ""} />
                        </ImageContainer>
                        <SubTitle>Check this Project out!</SubTitle>
                        <br/> <br/>
                        <TextContent>In this app you have to hit the piñana to get an advice... <br/> It's the mexican version of a fortune cookie. </TextContent>
                        <br/>
                        <br/>
                        <a href="https://github.com/jbc7ag/lucky-pinata" target="_blank" rel="noreferrer">
                            <ButtonContact> 
                                 Go to GitHub
                            </ButtonContact>
                        </a>
                    </SquareContainer>
                </Column2>
            </Container>
            <FooterContainer>
                <div>
                    <div>Icons made by <a href="https://www.freepik.com" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
                </div>
            </FooterContainer>
        </section>
    );
}

export default HomePage;
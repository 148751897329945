import React, {useRef, useEffect}  from 'react';
import { useArticlesContext } from '../../providers/BlogProvider/Provider';
import { useProjectsContext } from '../../providers/ProjectsProvider/Provider';
import { useThemeContext } from '../../providers/ThemeProvider/Provider';
import  ProjectItem  from '../../components/ProjectItem'
import ReactLoading from "react-loading";
import styled from 'styled-components'
import {ReactComponent as Cactus1} from '../../images/cactus.svg';
import {ReactComponent as Cactus2} from '../../images/cactus1.svg';
import {ReactComponent as Cactus3} from '../../images/cactus2.svg';
import './Projects.styles.scss';
import { device } from '../../utils/devices';

const Container = styled.div`
    width: 90%;
    min-height: 100vh;
    margin: auto;
`;

const HeaderContainer = styled.div`
  width: 100%;
  height: 100px;
  background-image: radial-gradient(${(props) => props.theme.primaryText} 20%, transparent 20%),
  radial-gradient(${(props) => props.theme.primaryText} 20%, transparent 20%);
  background-size: 20px 20px;
  padding: 10px;
  text-align: center;
  .title-container{
      background-color: ${(props) => props.theme.body};
      width: fit-content;
      margin: auto;
      margin-top: 15px;
  }
  .select {
    background: ${(props) => props.theme.background};
    padding-left: 6px;
    padding-right: 4px;
    }
`;


const Title = styled.span`
    font-size: 62px;
    font-family: 'Passion One';
    letter-spacing: .08rem;
    color: ${(props) => props.theme.primaryText};
`;

const BlogSubTitle = styled.span`
    font-size: 20px;
    font-family: 'Passion One';
    letter-spacing: .08rem;
    color: ${(props) => props.theme.primaryText};
`;

const NothingToShow = styled.div`
    width: 100%;
    margin-top: 100px;
    text-align:center;
    div{
        display: flex;
        align-items: center;
        justify-content: center;
        svg{
            width: 150px;
            height: 150px;
            fill: ${(props) => props.theme.primaryText};
        }
        margin-bottom: 30px;
    }
    span{
        color: ${(props) => props.theme.primaryText};
        font-size: 20px;
        font-family: 'DM Sans';
    }

`;

const Loading = styled.div`
    text-align: center;
    width: 150px;
    margin:auto;
    margin-top: 100px;
    span{
        font-size: 20px;
    }
`;

const ProjectsContainer = styled.div`
    width: 100%;
    margin-top: 50px;
    display: grid;
    grid-template-columns: 100%;
    justify-content: center;
    gap: 20px;
    margin-bottom: 100px;

    @media only screen and ${device.tablet}{ 
        grid-template-columns: 25% 25% 25%;
        margin-bottom: 10px;
        gap: 10%;
      }
`;

const TitleContainer = styled.div`
    width: 100%;
    margin-top: 50px;
    margin-left: unset;
    font-size: 20px;
    font-family: 'DM Sans';
    font-weight: bold;

    @media only screen and ${device.tablet}{ 
        margin-left: 20px;
    }
`;




function ProjectsPage(){

  const sectionRef = useRef(null);
  const { projects, fetchProjects, loading } = useProjectsContext();
  const { setCurrentScreen } = useArticlesContext();
  const { theme } = useThemeContext();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setCurrentScreen(3);
    console.log(projects.length);
    if(projects.length === 0){
       fetchProjects();
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
},[]);

    return(

        <section className="projects-page" ref={sectionRef}>
              <Container>
                <HeaderContainer>
                    <div className="title-container">
                        <Title>
                            Projects 
                        </Title>
                        <BlogSubTitle className="select"> 
                            Boring?
                        </BlogSubTitle>
                    </div>
                </HeaderContainer>
                <TitleContainer>
                    From my blog or just playing around
                </TitleContainer>
                <ProjectsContainer>
                {!loading ?  projects.length <= 0 ?
                                <NothingToShow>
                                    <div>
                                        <Cactus1/>
                                        <Cactus2/>
                                        <Cactus3/>
                                    </div>
                                    <span>There's nothing to show yet :(</span>
                                </NothingToShow>
                                :
                                projects.map((item, index) =>{
                                    return <ProjectItem key={index} item={item}/>
                                })
                    : <Loading>
                        <ReactLoading type="bars" color={theme.primaryText }/>
                      </Loading>
                } 
                </ProjectsContainer>
            </Container>
        </section>
    );
}

export default ProjectsPage;
import React, {useRef, useEffect, useState}  from 'react';
import { useThemeContext } from '../../providers/ThemeProvider/Provider';
import ReactLoading from "react-loading";
import {ReactComponent as Cactus1} from '../../images/cactus.svg';
import {ReactComponent as Cactus2} from '../../images/cactus1.svg';
import {ReactComponent as Cactus3} from '../../images/cactus2.svg';
import styled from 'styled-components'
import WritingsItem from '../../components/WritingsItem';
import TagItem from '../../components/TagItem';
import './Writings.styles.scss';
import { useArticlesContext } from '../../providers/BlogProvider/Provider';
import { device } from '../../utils/devices';



const Container = styled.div`
    width: 90%;
    min-height: 100vh;
    margin: auto;
    margin-bottom: 50px;
`;

const HeaderContainer = styled.div`
  width: 100%;
  height: 100px;
  background-image: radial-gradient(${(props) => props.theme.primaryText} 20%, transparent 20%),
  radial-gradient(${(props) => props.theme.primaryText} 20%, transparent 20%);
  background-size: 20px 20px;
  padding: 10px;
  text-align: center;
  .title-container{
      background-color: ${(props) => props.theme.body};
      width: fit-content;
      margin: auto;
      margin-top: 15px;
  }
  .select {
    background: ${(props) => props.theme.background};
    padding-left: 6px;
    padding-right: 4px;
    }
`;

const Tags = styled.div`
  width: 90%;
  margin:auto;
  text-align: center;
  padding-top: 30px;
  display: none;

  @media only screen and ${device.tablet}{ 
    display: block;
  }
`;

const ArticleContainer = styled.div`
   width: 100%;
   margin: auto;
`;

const OrderBy = styled.div`
   width: 100%;
   text-align: end;
   margin-top: 20px;
   font-family: 'DM Sans';
   font-size: 14px;
   span {
    font-weight: bold;
    margin-left: 10px;
    cursor: pointer;
    &:hover{
        color: ${(props) => props.theme.background};
        font-weight: bold;
    }
    &.active{
       
        color: ${(props) => props.theme.background};
           
        }
    }
    &.hide{
        display:none;
    }

    @media only screen and ${device.tablet}{ 
        font-size: 18px;
      }
`;


const Title = styled.span`
    font-size: 62px;
    font-family: 'Passion One';
    letter-spacing: .08rem;
    color: ${(props) => props.theme.primaryText};
`;

const BlogSubTitle = styled.span`
    font-size: 20px;
    font-family: 'Passion One';
    letter-spacing: .08rem;
    color: ${(props) => props.theme.primaryText};
`;

const Loading = styled.div`
    text-align: center;
    width: 150px;
    margin:auto;
    margin-top: 100px;
    span{
        font-size: 20px;
    }
`;

const NothingToShow = styled.div`
    width: 100%;
    margin-top: 50px;
    text-align:center;
    div{
        display: flex;
        align-items: center;
        justify-content: center;
        svg{
            width: 150px;
            height: 150px;
            fill: ${(props) => props.theme.primaryText};
        }
        margin-bottom: 30px;
    }
    span{
        color: ${(props) => props.theme.primaryText};
        font-size: 20px;
        font-family: 'DM Sans';
    }

`;

function WritingsPage(){

  const sectionRef = useRef(null);
  const { theme } = useThemeContext();
  const { articles, fetchArticles, loading, setCurrentScreen } = useArticlesContext();
  const [ tagList, setTagList ] = useState([]);
  const [ articlesFilter, setArticlesFilter ] = useState([]);
  const [ active, setActive ] = useState(0);

  useEffect(() => {
     window.scrollTo({ top: 0, behavior: 'smooth' });
      setCurrentScreen(2);
      if(articles.length === 0){
         fetchArticles();
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    if(articles.length > 0){
        setArticlesFilter(articles);

        const itemAll = {
            name : "All",
            active: true
        }
       let tags = [itemAll];
        articles.forEach((item, index) => {
            tags = tags.concat(
                item.tags.map((item)=>{
                    return { name: item, active: false }
                 }));
        });

        tags = tags.filter((v,i,a)=>a.findIndex(t=>(t.name === v.name))===i)

        setTagList(tags);
    }
  }, [articles]);

  const selectTag =(tag)=>{
    if (tagList) {
        const newItem = [];
        tagList.map((item) => {
          return newItem.push({
            ...item,
            active: item.name === tag.name,
          });
        });
        setTagList(newItem);
        filterArticles(tag);
      }
  }

  const filterArticles =(tag)=>{
    let filterd = articles;

    if(tag.name !== "All"){
       filterd = articles.filter((item) => { 
          return item.tags.findIndex((item) => { return item === tag.name}) >= 0;
      });
    }
      setArticlesFilter(filterd);
  }

  const oderByDate =(type)=>{
   let orderItems = [];
   
   if(type === "asc"){
        setActive(0);
        orderItems = articlesFilter.sort((a, b) => new Date(b.date) - new Date(a.date));
    } else{
        setActive(1);
        orderItems = articlesFilter.sort((a, b) => new Date(a.date) - new Date(b.date));
    }
   
    setArticlesFilter(orderItems);
}

    return(
        <section className="writings-page" ref={sectionRef}>
            <Container>
                <HeaderContainer>
                    <div className="title-container">
                        <Title>
                            Blog 
                        </Title>
                        <BlogSubTitle className="select"> 
                            Latest ones
                        </BlogSubTitle>
                    </div>
                </HeaderContainer>
                <Tags> 
                    {tagList && tagList.map((item, index) =>{
                        return <TagItem key={index} item={item} onClick={()=>selectTag(item)}/>
                    })}
                </Tags>
                <ArticleContainer>
                <OrderBy className={ articlesFilter.length <= 0 ? "hide": ""}>Order by: <span className={active === 0 ? "active": ""} onClick={()=> oderByDate("asc")}>Latest</span> <span className={active === 1 ? "active": ""} onClick={()=> oderByDate("desc")} >Oldest</span></OrderBy>
                {!loading ?  articlesFilter.length <= 0 ?
                                <NothingToShow>
                                    <div>
                                        <Cactus1/>
                                        <Cactus2/>
                                        <Cactus3/>
                                    </div>
                                    <span>There's nothing to show :(</span>
                                </NothingToShow>
                                :
                                articlesFilter.map((item, index) =>{
                                    return <WritingsItem item={item} key={index} onClick={()=>selectTag(item)}/>
                                })
                    : <Loading>
                        <ReactLoading type="bars" color={theme.primaryText }/>
                      </Loading>
                } 
                </ArticleContainer>
            </Container>
        </section>
    );
}

export default WritingsPage;
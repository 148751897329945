import React from 'react';
import { useThemeContext } from '../../providers/ThemeProvider/Provider';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons';
import './ThemeSwitch.styles.scss';

const LogoName = styled.span`
  margin: 8px;
  font-weight: bold;
  font-size: 20px;
  color: ${(props) => props.theme.primaryText};
  span svg{
     padding-right: 10px;
     cursor: pointer;
     margin-left: 10px;
   }
`;

function ThemeSwitch(props) {
  const { setTheme, isLightTheme } = useThemeContext();
 
  return (

    <LogoName onClick={()=> setTheme()}>
    <span>
        { isLightTheme ?
        <FontAwesomeIcon icon={faSun} size="lg" /> 
        : 
        <FontAwesomeIcon icon={faMoon} size="lg" /> 
        }
    </span>
    </LogoName>

      /*  <div class="toggleWrapper">
            <input type="checkbox" class="dn" id="dn" onChange={() => setTheme()}/>
            <label for="dn" class="toggle">
                <span class="toggle__handler">
                <span class="crater crater--1"></span>
                <span class="crater crater--2"></span>
                <span class="crater crater--3"></span>
                </span>
                <span class="star star--1"></span>
                <span class="star star--2"></span>
                <span class="star star--3"></span>
                <span class="star star--4"></span>
                <span class="star star--5"></span>
                <span class="star star--6"></span>
            </label>
        </div> */
  );
}

export default ThemeSwitch;

import React,  { useState, useEffect }  from 'react';
import { useArticlesContext } from '../../providers/BlogProvider/Provider';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Item = styled.div`
  padding: 15px;
  align-items: baseline;
`;

const ItemContainer = styled.div`
  .label {
    font-size: 14px;
    font-family: 'Passion One';
    letter-spacing: .08rem;
    color: ${(props) => props.theme.primaryText};

    &-active {
      color: ${(props) => props.theme.itemColor};
    }
  }
  a {
    text-decoration: none;
    cursor: pointer;
    &:hover{
      text-decoration-line: underline;
      text-decoration-style: wavy;
      text-decoration-color: ${(props) => props.theme.itemColor};
    }
  }
`;

const Items = [
    {
      id: 1,
      name: 'HOME',
      route: '/',
      active: true,
    },
    {
      id: 2,
      name: 'BLOG',
      route: '/blog',
      active: false,
    },
    {
      id: 3,
      name: 'FUN',
      route: '/fun',
      active: false,
    },
    {
      id: 4,
      name: 'FIND ME',
      route: '/findme',
      active: false,
    }
  ];

  
function MenuItems(props) {

  const { currentScreen } = useArticlesContext();
  const [items, setItems] = useState(Items);
  
  useEffect(() => {

        const newItems = items.map((item) => {
          return {
            ...item,
            active: item.id === currentScreen
          }
        });
        setItems(newItems);
      
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentScreen]);


    return (
        <>
        { items.map((item)=> {
            return (
                <ItemContainer key={item.name}>
                            <Link to={item.route}>
                            <Item>
                                <span className={item.active ? ' label label-active' : 'label'}>
                                {item.name}
                                </span>
                            </Item>
                            </Link>
                </ItemContainer>
            )})
        }
        </>
    )
}

export default MenuItems;
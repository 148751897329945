import React from 'react';
import styled from 'styled-components'; 
import { device } from '../../utils/devices';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';



const ItemProject = styled.div`
  width: 100%;
  min-height: 300px;
  font-family: 'DM Sans';
  position: relative; 
`;

const ImageContainer = styled.div`
  padding-top: 20px;
  height: 230px;
  width: 100%;
  border-radius: 10px;
  background: url(${(props) => props.imgUrl});
  background-size:     cover;                     
  background-repeat:   no-repeat;
  background-position: center center; 
`;

const Title = styled.div`
 width: 100%;
 font-weight: bold;
 margin-top: 20px;
 font-size: 14px;
 color: ${(props) => props.theme.background};

 @media only screen and ${device.tablet}{ 
  font-size: 18px;
}
`;

const Description = styled.div`
  width: 100%;
  text-align: right;
  display: grid;
  a{
    text-decoration: none;
    color: ${(props) => props.theme.primaryText};
    font-weight: bold;
    font-size: 16px;
    padding-top: 20px;
    &:hover{
      color: ${(props) => props.theme.background};
    }
  }
`;

const TextContent = styled.div`
  margin-top: 10px;
  width: auto;
  font-size: 14px;
  text-align: justify;
  margin-top: 20px;

  @media only screen and ${device.tablet}{ 
    font-size: 18px;
  }
`;
  
function ProjectItem({item}) {

  const {id, title, description, imageUrl, url } = item;

    return (
        <ItemProject id={id}>
          <ImageContainer imgUrl={imageUrl}></ImageContainer>
          <Title> {title} </Title>
          <Description>
            <TextContent>{description}</TextContent>
            <a href={url} target="_blank" rel="noreferrer">
              Code <FontAwesomeIcon icon={faArrowRight} size="1x" />
            </a>
          </Description>
        </ItemProject>
    )
}

export default ProjectItem;
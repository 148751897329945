import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ArticleProvider from '../../providers/BlogProvider/Provider';
import ProjectsProvider from '../../providers/ProjectsProvider/Provider';
import ThemeContext from '../../providers/ThemeProvider/Provider';
import AppTheme from '../AppTheme';


function App() {

    return (
      <BrowserRouter>
          <ArticleProvider>
            <ProjectsProvider>
              <ThemeContext>
                <AppTheme />
              </ThemeContext>
            </ProjectsProvider>
          </ArticleProvider>
      </BrowserRouter>
    );
}
  
export default App;

import { ACTIONS } from './Actions';
import { THEME_STORAGE_KEY } from '../../utils';
import { storage } from '../../utils';

export const lightTheme = {
  body: '#FAFAFA',
  text: '#363537',
  primaryText: '#393A3B', 
  secondaryText: '#6A6A68',
  background: '#4FB2AD', // buttons & containers
  itemColor: '#4FB2AD', // menu items
  divider: '#474A4C', //lines
  buttons: '#FAFAFA',
  botIcon: '#48BB67',
  coinIcon: '#F7C835',
  codeBackground: 'black'
};

export const darkTheme = {
  body: '#222831',
  text: '#FAFAFA',
  primaryText: '#F1F0ED',
  secondaryText: '#E1E0DD',
  background: '#0D7377',
  itemColor: '#0D7377',
  divider: '#DDDDDD',
  buttons: '#222831',
  botIcon: '#48BB67',
  coinIcon: '#F7C835',
  codeBackground: 'black'
};

const isLightTheme = storage.get(THEME_STORAGE_KEY);

export const initialState = {
  isLightTheme: isLightTheme,
  theme: isLightTheme ? lightTheme: darkTheme,
};

//toggle
export function ThemeReducer(state, action) {
  const { type } = action;

  switch (type) {
    case ACTIONS.SET_THEME:

      //Save theme in the storage
      storage.set(THEME_STORAGE_KEY, !state.isLightTheme);

      return {
        isLightTheme: !state.isLightTheme,
        theme: state.isLightTheme ? darkTheme : lightTheme,
      };
    default:
      return new Error(`Invalid action "${type}"`);
  }
  
}

const ACTIONS = {
    FETCH_ARTICLES: 'FETCH_ARTICLES',
    FETCH_SUCCESS: 'FETCH_SUCCESS',
    FETCH_ERROR: 'FETCH_ERROR',
    CURRENT_ARTICLE: 'CURRENT_ARTICLE',
    FETCH_SCREEN: 'FETCH_SCREEN',
    CURRENT_SCREEN: 'CURRENT_SCREEN',
    FETCH_CONTENT_SUCCESS: 'FETCH_CONTENT_SUCCESS',
    FETCH_CONTENT: 'FETCH_CONTENT'
  };
  
  export { ACTIONS };
  
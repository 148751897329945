import { ACTIONS } from './Actions';

export const initialState = {
  error: false,
  loading: false,
  articles: [],
  currentArticle: {},
  currentScreen: 1
};


export function reducer(state, action) {
    const { type, payload = {} } = action;
  
    switch (type) {
      case ACTIONS.FETCH_ARTICLE:
        return {
          ...state,
          loading: true,
          error: false,
        };
      case ACTIONS.FETCH_SUCCESS:
        return {
          ...state,
          loading: false,
          articles: payload.articles,
        };
      case ACTIONS.FETCH_ERROR:
        return {
          ...state,
          loading: false,
          error: payload.error,
        };
      case ACTIONS.CURRENT_ARTICLE:
        return {
          ...state,
          currentArticle: payload.currentArticle,
        };
        case ACTIONS.CURRENT_SCREEN:
          return {
            ...state,
            currentScreen: payload.currentScreen,
          };
        case ACTIONS.FETCH_CONTENT_SUCCESS:
          const currentArticle = {
            ...state.currentArticle,
            content : payload.data.content,
            title : payload.data.title,
            tags : payload.data.tags,
            url : payload.data.url
          }
        return {
          ...state,
          loading: false,
          currentArticle
        };
        case ACTIONS.FETCH_CONTENT:
          return {
            ...state,
            loading: true,
            error: false,
          };
      default:
        return {
          ...state,
          error: 'Error',
        };
    }
  }
  
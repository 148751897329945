import React from 'react';
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGhost } from '@fortawesome/free-solid-svg-icons';

const LogoName = styled.span`
  margin: 8px;
  font-weight: bold;
  font-size: 20px;
  font-family: 'Passion One';
  color: ${(props) => props.theme.primaryText};
  span svg{
     padding-right: 10px;
   }
`;

function Logo(){
    return(
        <LogoName>
        <span>
            <FontAwesomeIcon icon={faGhost} size="1x" /> 
            JESS
        </span>
        </LogoName>
       
    );
}

export default Logo;
import React, { useReducer, createContext, useContext } from 'react';
import he from 'he';
import { reducer, initialState } from './Reducer';
import { ACTIONS } from './Actions';
import {getArticles, getContent} from '../../api/devto';


const ArticlesContext = createContext(null);

function useArticlesContext() {
    const context = useContext(ArticlesContext);
    return context;
  }

const ArticleProvider = ({children}) =>{
    const [state, dispatch] = useReducer(reducer, initialState);

    const fetchArticles = (dptch) => async () => {
       
        dptch({ type: ACTIONS.FETCH_ARTICLES });
        
        try {
          const results = await getArticles();

          const articles = results.map((item) => {
            const { title, id, description, published_at, tag_list, url, public_reactions_count, page_views_count, body_markdown, reading_time_minutes, edited_at  } = item;
          
            return {
              id: id,
              title: he.decode(title),
              description: he.decode(description),
              content: body_markdown,
              tags: tag_list,
              reactions: public_reactions_count,
              views: page_views_count,
              date: published_at,
              editDate: edited_at,
              readTime: reading_time_minutes,
              url
            };
          });

          dptch({ type: ACTIONS.FETCH_SUCCESS, payload: { articles } });
          return articles;

        } catch (error) {
          dptch({ type: ACTIONS.FETCH_ERROR, payload: { error: error.message }});
          return null;
        }
      };

      const fetchContent = (dptch, states) => async (id) => {
       
        dptch({ type: ACTIONS.FETCH_CONTENT});
        
        try {

          const results = await getContent(id);
          const { body_html, title, tags, url } = results;
          
          const data = {
            content: body_html,
            title,
            tags,
            url
          };

          dptch({ type: ACTIONS.FETCH_CONTENT_SUCCESS, payload: { data } });
          return data;

        } catch (error) {
          dptch({ type: ACTIONS.FETCH_ERROR, payload: { error: error.message }});
          return null;
        }
      };

      const setCurrentArticle = (dptch, states) => async (id) => {
       
        const { articles } = states;
        const currentArticle = articles.filter((item) => item.id === parseInt(id))[0];
        dptch({ type: ACTIONS.CURRENT_ARTICLE, payload: { currentArticle } });
      };

      const setCurrentScreen =(dispatch, state) => async (currentScreen) =>{      
        dispatch({ type: ACTIONS.CURRENT_SCREEN, payload: { currentScreen } });
      };

      const value = {
        loading: state.loading,
        error: state.error,
        articles: state.articles,
        fetchArticles: fetchArticles(dispatch),
        currentArticle: state.currentArticle,
        setCurrentArticle: setCurrentArticle(dispatch, state),
        currentScreen: state.currentScreen,
        setCurrentScreen: setCurrentScreen(dispatch, state),
        fetchContent: fetchContent(dispatch, state)
      };
    
      return <ArticlesContext.Provider value={value}>{children}</ArticlesContext.Provider>;
}

export { useArticlesContext };
export default ArticleProvider;
import React from 'react';
import styled from 'styled-components';
import Moment from 'moment';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faClock } from '@fortawesome/free-solid-svg-icons';
import { device } from '../../utils/devices';


const ItemArticles = styled.div`
  min-height: 150px;
  margin-top: 20px;
  border: 1px solid ${(props) => props.theme.divider};
  border-left: 10px solid ${(props) => props.theme.background};
  font-family: 'DM Sans';
  padding: 10px;
`;

const Date = styled.div`
  width: auto;
  font-size: 12px;
`;

const Title = styled.div`
  width: auto;
  font-weight: bold;
  font-size: 20px;
  min-height: 50px;
  margin-top: 20px;
  a{
    text-decoration: none;
    color: ${(props) => props.theme.primaryText};
    &:hover{
      color: ${(props) => props.theme.background};
    }
  }

  @media only screen and ${device.tablet}{ 
    font-size: 25px;
  }
`;

const SubTitle = styled.div`
    width: auto;
    font-size: 15px;
    min-height: 40px;
    margin-top: 10px;
    text-align: justify;
    
    @media only screen and ${device.tablet}{ 
      font-size: 18px;
      margin-top: unset;
    }
`;

const Tags = styled.div`
  width: 100%;
  font-size: 14px;

  span{
    width: auto;
    font-family: 'DM Sans';
    padding: 5px;
    text-transform:capitalize;
    display: inline-block;
    color: ${(props) => props.theme.background};
    font-weight: bold;
    &:first-child{
      padding-left: unset;
  }
`;


const Reactions = styled.div`
  width: 100%;
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 12px;

  @media only screen and ${device.tablet}{ 
    font-size: 18px;
  }
`;

const TimeToRead = styled.div`
  width: 40%;
  float: right;
  text-align: end;
  padding-right: 10px;
`;


const randomNumber = () => {
  const number = ["6", "4", "3", "5"];
  return number[Math.floor(Math.random()*number.length)];

}

const formatDate = (date) => {
  Moment.locale('en');
  return Moment(date).format('D MMM \'YY');
}
  
function WritingsItem({item, onClick}) {

  const {id, title, date, description, tags, reactions, readTime = randomNumber()  } = item;

    return (
        <ItemArticles id={id}>
            <Date> { formatDate(date)}.</Date>
            <Title> <Link to={"/post/"+id}>{title}</Link></Title>
            <SubTitle>{description} </SubTitle>
            <Tags>
              {tags.map((item, index) =>{
                return <span key={index}>{item}</span>
              })}
        
            </Tags>
            <Reactions>
                <FontAwesomeIcon icon={faThumbsUp} size="1x" /> {reactions} reactions
                <TimeToRead><FontAwesomeIcon icon={faClock} size="1x" /> {readTime} min read</TimeToRead>
            </Reactions>

        </ItemArticles>
    )
}

export default WritingsItem;
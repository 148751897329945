import React from 'react';
import styled from 'styled-components';

const ItemTag = styled.span`
  width: auto;
  font-family: 'DM Sans';
  padding: 10px;
  text-transform:capitalize;
  cursor: pointer;
  display: inline-block;
  border: 1px solid ${(props) => props.theme.primarText};
  margin-right: 10px;
  &:hover{
    color: ${(props) => props.theme.primaryText};
    font-weight: bold;
    background-color: ${(props) => props.theme.background};
  }
  &.selected{
    color: ${(props) => props.theme.primarText};
    font-weight: bold;
    background-color: ${(props) => props.theme.background};
  }
`;

  
function TagItem({item, onClick}) {
    return (
        <ItemTag className={item.active ? "selected" : ""} onClick={()=> onClick()} >{item.name}</ItemTag>
    )
}

export default TagItem;
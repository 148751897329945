import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { device } from '../../utils/devices';
import MenuItems from '../MenuItems';
import ThemeSwitch from '../ThemeSwitch';
import Logo from '../Logo'
import './Navbar.styles.scss';

const NavBarParent = styled.div`
  height: 84px;
  border-bottom: 2px solid ${(props) => props.theme.divider};
`;

const LogoContainer = styled.div`
  height: 100%;
  float: left;
  width: 20%;
  display: flex;
  align-items: center; 
  justify-content: center;

  a {
    text-decoration: none;
  }
`;

const ItemsCotainer = styled.div`
  height: 100%;
  float: right;
  display: none;
  align-items: center; 
  justify-content: center;
  margin-right: 100px;

  @media only screen and ${device.tablet}{ 
    display: flex;
  }

`;

const ThemeSwitchContainer = styled.div`
  height: 100%;
  float: right;
  width: 10%;
  display: flex;
  align-items: center; 
  justify-content: center;
  border-left: 2px solid ${(props) => props.theme.divider};
`;



function NavBar() {

  return (
      <NavBarParent>
          <LogoContainer>
            <Link to="/">
              <Logo/>
            </Link>
          </LogoContainer>
          <ThemeSwitchContainer>
            <ThemeSwitch/>
          </ThemeSwitchContainer>
          <ItemsCotainer>
            <MenuItems />
          </ItemsCotainer>
      </NavBarParent>
  );
}

export default NavBar;

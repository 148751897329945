export default async function getProjects() {
    return [
        {
            id: 1,
            title: "APOD",
            description: "In this App we call some NASA APIs to get the Astronomic Picture of the Day or search for a picture.",
            url: "https://github.com/jbc7ag/apod",
            imageUrl: "https://raw.githubusercontent.com/jbc7ag/jbc7ag/master/images/apod.jpg"
        },
        {
            id: 1,
            title: "Lucky Piñata",
            description: "Android app where you have to hit the piñana to get an advice. Example of Retrofit + Coroutines.",
            url: "https://github.com/jbc7ag/lucky-pinata",
            imageUrl: "https://raw.githubusercontent.com/jbc7ag/jbc7ag/master/images/pinata.jpg"
        },
        {
            id: 2,
            title: "Android Notifications",
            description: "See how style your notifications on an interactive Android app. From basic to chat like notifications. ",
            url: "https://github.com/jbc7ag/Notifications",
            imageUrl: "https://raw.githubusercontent.com/jbc7ag/jbc7ag/master/images/notification.png"
        },
        {
            id: 3,
            title: "Reciclerview or ListView?",
            description: "Doubts about RecyclerView and ListView? Check this code out and see how implement them.",
            url: "https://github.com/jbc7ag/recyclerview-vs-listview",
            imageUrl: "https://raw.githubusercontent.com/jbc7ag/jbc7ag/master/images/list.png"
        }];

}
    
import { ACTIONS } from './Actions';

export const initialState = {
  error: false,
  loading: false,
  projects: [],
};


export function reducer(state, action) {
    const { type, payload = {} } = action;
  
    switch (type) {
      case ACTIONS.FETCH_PROJECTS:
        return {
          ...state,
          loading: true,
          error: false,
        };
      case ACTIONS.FETCH_PROJECTS_SUCCESS:
        return {
          ...state,
          loading: false,
          projects: payload.projects,
        };
      case ACTIONS.FETCH_PROJECTS_ERROR:
        return {
          ...state,
          loading: false,
          error: payload.error,
        };
      default:
        return {
          ...state,
          error: 'Error',
        };
    }
  }
  
import React, { useReducer, createContext, useContext } from 'react';
import he from 'he';
import { reducer, initialState } from './Reducer';
import { ACTIONS } from './Actions';
import getProjects from '../../api/github';


const ProjectsContext = createContext(null);

function useProjectsContext() {
    const context = useContext(ProjectsContext);
    return context;
  }

const ProjectsProvider = ({children}) =>{
    const [state, dispatch] = useReducer(reducer, initialState);

    const fetchProjects= (dptch) => async () => {
       
        dptch({ type: ACTIONS.FETCH_PROJECTS });
        
        try {
          const results = await getProjects();

          const projects = results.map((item) => {
            
            const { title, id, description, url, imageUrl  } = item;
          
            return {
              id,
              title: he.decode(title),
              description: he.decode(description),
              imageUrl,
              url
            };
            
          });

          dptch({ type: ACTIONS.FETCH_PROJECTS_SUCCESS, payload: { projects } });
          return projects;

        } catch (error) {
          dptch({ type: ACTIONS.FETCH_PROJECTS_ERROR, payload: { error: error.message }});
          return null;
        }
      };


      const value = {
        loading: state.loading,
        error: state.error,
        projects: state.projects,
        fetchProjects: fetchProjects(dispatch)
      };
    
      return <ProjectsContext.Provider value={value}>{children}</ProjectsContext.Provider>;
}

export { useProjectsContext };
export default ProjectsProvider;
import React, {useRef, useEffect}  from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components'
import { Link } from 'react-router-dom';
import { useArticlesContext } from '../../providers/BlogProvider/Provider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import { faDev } from '@fortawesome/free-brands-svg-icons';
import {ReactComponent as Cactus1} from '../../images/cactus.svg';
import {ReactComponent as Cactus2} from '../../images/cactus1.svg';
import {ReactComponent as Cactus3} from '../../images/cactus2.svg';
import { device } from '../../utils/devices';
import './PostDetail.styles.scss';



const Container = styled.div`
    width: 80%;
    margin: auto;


`;

const GoBack = styled.div`
    width: 100%;
    text-align: left;
    margin-left: 0px;
    font-family: 'DM Sans';
    a{
        text-decoration: none;
        font-size: 20px;
        font-weight: bold;
        color: ${(props) => props.theme.primaryText};
        &:hover{
            color: ${(props) => props.theme.background};
        }
    }
    @media only screen and ${device.tablet}{ 
        margin: 20px;
    }
`;

const Header = styled.div`
    width: 100%;
    text-align: center;
    @media only screen and ${device.tablet}{ 
        text-align: left;
    }

    a>span:hover{
        color: ${(props) => props.theme.background};
    }
`;

const SubHeader = styled.div`
    width: 100%;
    text-align: left;
    font-size: 20px;
    font-family: 'Passion One';
    letter-spacing: .08rem;
    color: ${(props) => props.theme.background};
    display:inline-block;
    margin: 0px 10px 0px 20px;
   
    a{
        text-decoration: none;
        color: ${(props) => props.theme.background};
        &:hover{
            color: ${(props) => props.theme.primaryText};
        }
    }
`;

const Title = styled.span`
    font-size: 30px;
    font-family: 'Passion One';
    letter-spacing: .08rem;
    color: ${(props) => props.theme.primaryText};
    display:inline-block;
    margin: 20px 0px 20px 0px;

    @media only screen and ${device.tablet}{ 
        font-size: 62px;
        margin: 20px;
    }

`;

const NothingToShow = styled.div`
    width: 100%;
    margin-top: 100px;
    text-align:center;
    div{
        display: flex;
        align-items: center;
        justify-content: center;
        svg{
            width: 150px;
            height: 150px;
            fill: ${(props) => props.theme.primaryText};
        }
        margin-bottom: 30px;
    }
    span{
        color: ${(props) => props.theme.primaryText};
        font-size: 20px;
        font-family: 'DM Sans';
    }

`;
/*
const Tags = styled.div`
  width: 100%;
  margin:auto;
  text-align: left;

`;

const Tag = styled.span`
    font-size: 20px;
    font-family: 'Passion One';
    letter-spacing: .08rem;
    color: ${(props) => props.theme.background};
    display:inline-block;
    margin: 0px 10px 0px 20px;
    text-transform:capitalize;
`;*/
const Content = styled.div`
    font-size: 16px;
    font-family: 'DM Sans';
    margin-top: 50px;
    text-align: justify;
    text-justify: inter-word;
    margin-bottom: 100px;
    @media only screen and ${device.tablet}{ 
        margin-bottom: 50px;
        font-size: 20px;
    }
    .highlight__panel{
        display: none;
    }
    .highlight{
        padding: 5px;
        overflow-y: auto;
        background: #282a36;
        font-size: 14px;
        @media only screen and ${device.tablet}{ 
            font-size: 20px;
        }
        &.ssh{
            color: #50fa7b;
        }
        &.kotlin, &.java, &.xml, &.json {
            .c1, .cm{
                color:  #6272a4;
            }
            .p, .kt, .cp{
                color: #f1fa8c;
            }
            .kd{
                color: #ff79c6;
            }
            .py, .na, .o{
                color: #bd93f9;
            }
            .nf, .mf, .nt, .nn{
                color: #50fa7b;
            }
            .nc, .k, .nl{
                color: #8be9fd;
            }
            .n, .nd{
                color: #ffb86c;
            }
            .s, .mi, .err, .s2{
                color: #ff5555;
            }
        }
    }
    img{
        margin-top: 20px;
        width: 100%;
        @media only screen and ${device.tablet}{ 
            width: 60%;
        }
    }
    a{
        text-decoration: none;
        color: ${(props) => props.theme.background};
        &:hover{
            color: ${(props) => props.theme.primaryText};
        }
    }
`;



function PostDetail(){

  const sectionRef = useRef(null);
  const { setCurrentScreen, setCurrentArticle, currentArticle, fetchContent } = useArticlesContext();
  const { id } = useParams();

  

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setCurrentScreen(2);
    setCurrentArticle(id);
    fetchContent(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return(
        <section className="post-page" ref={sectionRef}>
            <Container>
                    {currentArticle ?
                        <>
                            <GoBack>
                                <Link to={'/blog'}>
                                    <FontAwesomeIcon icon={faLongArrowAltLeft} size="1x" /> Back
                                </Link>
                            </GoBack>
                            <Header>
                                <Title> {currentArticle.title} </Title>
                            </Header> 
                            <SubHeader>  
                                <a href={currentArticle.url} target="_blank" rel="noreferrer">
                                    Go to original post on <span>DEV.to</span>
                                </a>
                            </SubHeader>
                          {/*  <Tags>
                            {currentArticle.tags && currentArticle.tags.map((item, index) =>{
                                return <Tag key={index}>{item}</Tag>
                            })}

                        </Tags> */}
                            <Content>
                            <div dangerouslySetInnerHTML={{
                                    __html: currentArticle.content
                                }}/>               
                            </Content>
                        </>
                    :
                        <NothingToShow>
                            <div>
                                <Cactus1/>
                                <Cactus2/>
                                <Cactus3/>
                            </div>
                            <span>Post not found! :(</span>
                        </NothingToShow>
                    }
                    
            </Container>
            
        </section>
    );
}

export default PostDetail;
export async function getArticles() {
var result = []; 

await fetch('https://dev.to/api/articles?username=jbc7ag', {
  method: "GET",
  mode:"cors",
  headers: {
    "accept":"application/json, text/plain, */*",
    "Origin": "https://findjessica.dev/"

  }
})
  .then(response => response.json())
  .then(response => {
      result = response;
    });

  return result ? result : [];
}

export async function getContent(id) {
  var result = []; 
  
  await fetch('https://dev.to/api/articles/'+id, {
    method: "GET",
    mode:"cors",
    headers: {
      "accept":"application/json, text/plain, */*",
      "Origin": "https://findjessica.dev/"
  
    }
  })
    .then(response => response.json())
    .then(response => {
        result = response;
      });
  
    return result ? result : [];
  }
  

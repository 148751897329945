import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { useThemeContext } from '../../providers/ThemeProvider/Provider';
import HomePage from '../../pages/Home';
import ContactPage from '../../pages/Contact';
import WritingsPage from '../../pages/Writings';
import ProjectsPage from '../../pages/Projects';
import Layout from '../Layout';
import PostDetail from '../../pages/PostDetail';

function AppTheme() {
  const { theme } = useThemeContext();

  const GlobalStyles = createGlobalStyle`
    body {
      background-color: ${(props) => props.theme.body};
      color: ${(props) => props.theme.text}; 
      transition: 0.20s;
    }
  `;

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Layout>
        <Switch>
          <Route exact path="/">
            <HomePage />
          </Route>
          <Route path="/findme">
            <ContactPage />
          </Route>   
          <Route path="/blog">
            <WritingsPage />
          </Route>  
          <Route path="/fun">
            <ProjectsPage />
          </Route>  
          <Route path="/post/:id">
            <PostDetail />
          </Route>
          <Route path="*">
             <HomePage />
          </Route>
        </Switch>
      </Layout>
    </ThemeProvider>
  );
}

export default AppTheme;

import React, {useRef, useEffect}  from 'react';
import styled from 'styled-components'
import { useArticlesContext } from '../../providers/BlogProvider/Provider';
import {ReactComponent as Muneca} from '../../images/fist-bump.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faLinkedin, faGithub, faDev } from '@fortawesome/free-brands-svg-icons';
import { faHandPeace } from '@fortawesome/free-solid-svg-icons';
import { device } from '../../utils/devices';
import './Contact.styles.scss';



const ContactContainer = styled.div`
    width: 65%;
    margin: auto;
    display: unset;

    @media only screen and ${device.tablet}{ 
        display: flex;
    }

    .column{
        width: 80%;

        @media only screen and ${device.tablet}{ 
            width: 50%;
        }
    }
`;

const Header = styled.div`
    width: 100%;
    text-align: center;
    @media only screen and ${device.tablet}{ 
        text-align: left;

    }
`;

const Title = styled.span`
    font-size: 40px;
    font-family: 'Passion One';
    letter-spacing: .08rem;
    color: ${(props) => props.theme.primaryText};
    display:inline-block;
    margin: 20px;

    @media only screen and ${device.tablet}{ 
        font-size: 62px;
    }

`;

const Content = styled.div`
    width: 100%;
    padding: 30px;
    font-family: 'DM Sans';
    font-size: 16px;
    letter-spacing: .08rem;
    font-weight: bold;
    text-align: justify;
    text-justify: inter-word;
    
    .select {
        background: ${(props) => props.theme.background};
        padding-left: 6px;
        padding-right: 4px;
    }

    @media only screen and ${device.tablet}{ 
        font-size: 20px;
    }
`;

const SVGContainer = styled.div`
    text-align: center;
    padding: 10px;
    display: none;
    .muneca {
        width: 60%;
        height: auto;
        margin: 50px;
        fill: ${(props) => props.theme.primaryText};
    }
    @media only screen and ${device.tablet}{ 
        display: block;
    }
`;

const SocialIcons = styled.div`
    width: 100%;
    font-size: 30px;
    letter-spacing: .08rem;
    color: ${(props) => props.theme.primaryText};
    margin-top: 10px;
    margin-left: 20px;
    margin-bottom: 100px;
    svg{
        margin-right: 20px;
    }
    a{
        text-decoration:none;
        cursor: pointer;
        color: ${(props) => props.theme.primaryText};
    }

    text-align: center;
    @media only screen and ${device.tablet}{ 
        text-align: left;
        margin-top: 20px;

    }

`;


function ContactPage(){

  const sectionRef = useRef(null);
  const { setCurrentScreen } = useArticlesContext();
  

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setCurrentScreen(4);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return(
        <section className="contact-page" ref={sectionRef}>
            <ContactContainer>
                <div className="column">
                    <Header>
                        <Title> Hey there! </Title> <FontAwesomeIcon icon={faHandPeace} size="3x" />
                    </Header>
                    <Content> 
                        I'm always <span className="select">ready</span> to chat!
                        <br/><br/>
                        <span>Send me an email at <span className="select">jbc7ag@gmail.com</span> or if you prefer... a message on social media.</span>
                    </Content>
                    <SocialIcons>
                        <a href="https://twitter.com/jbc7ag" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faTwitter} size="1x" />
                        </a> 
                        <a href="https://github.com/jbc7ag" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faGithub} size="1x" />
                        </a> 
                        <a href="https://www.linkedin.com/in/jessica-barrientos" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faLinkedin} size="1x" />
                        </a> 
                        <a href="https://dev.to/jbc7ag" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faDev} size="1x" />
                        </a> 
                    </SocialIcons> 
                </div>
                <SVGContainer className="column">
                   <Muneca  className="muneca"  />
                </SVGContainer>
            </ContactContainer>
            
        </section>
    );
}

export default ContactPage;
import React, {useState} from 'react';
import styled from 'styled-components';
import MenuItems from '../MenuItems';
import NavBar from '../Navbar';
import { device } from '../../utils/devices';

import './Layout.styles.scss';

const Container = styled.div`
  background: linear-gradient(to right, 
    ${(props) => props.theme.body} 50%, 
    ${(props) => props.theme.body} 50%);
`;

const PageContainer = styled.main`
  height: auto;
  margin: auto;
  margin-top: 20px;
`;

const MenuItemsFooter = styled.div` 
    overflow: hidden;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.theme.body};
    border-top: 2px solid ${(props) => props.theme.divider};

    @media only screen and ${device.tablet}{ 
      display: none;
    }
`;

function Layout({ children }) {
  const [homeClic, setHomeClic] = useState(false);

  return (
      <Container className="dual-color">
        <NavBar />
        <PageContainer>
          {children}
        </PageContainer>
        <MenuItemsFooter>
           <MenuItems homeActive={homeClic} setHomeClic = {setHomeClic}/>
        </MenuItemsFooter>
      </Container>
  );
}

export default Layout;
